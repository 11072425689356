import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
//gatsby
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { fadeAnimation, fadeItem } from '../components/MainStyles/animations'
//components
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const News = ({ data, path }) => {
    const articles = data.allDatoCmsArticle.edges;
    return (
        <Layout path={path}>
            <SEO title="Aktualności" />
            <StyledNews>
                <StyledGridNews variants={fadeAnimation} initial="hidden" animate="show">
                    {articles.map(({ node }) => {
                        return (
                            <StyledBox variants={fadeItem} whileHover={{ scale: 1.05 }} key={node.slug}>
                                <StyledImage src={node.image.url} alt="" />
                                <StyledContent>
                                    <StyledTitle>
                                        <h2>{node.title}</h2>
                                    </StyledTitle>
                                    <StyledText>
                                        <StyledP>{node.content}</StyledP>
                                    </StyledText>
                                    <StyledButton>
                                        <StyledLink to={`/${node.slug}`}>więcej</StyledLink>
                                    </StyledButton>
                                </StyledContent>
                            </StyledBox>
                        );
                    }).reverse()}
                </StyledGridNews>
            </StyledNews>
        </Layout>

    )
}

const StyledNews = styled.section`
    min-height: 91vh;
    background: ${({ theme }) => theme.color.lightGray};
    display: flex;
    align-items: center;
    padding: 1rem 15rem;
    ${({ theme }) => theme.media.phone} {
        padding: 1rem 0;
    }
`

const StyledGridNews = styled(motion.div)`
    padding: 5rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(36rem, 1fr));
    grid-auto-rows: 56rem;
    grid-gap: 6rem 12rem;
    ${({ theme }) => theme.media.tablet} {
        margin: 6rem auto 0 auto;
    }
    ${({ theme }) => theme.media.phone} {
        width: 86vw;
    }
`

const StyledBox = styled(motion.div)`
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 9px rgba(197, 193, 207, 0.6);
    transform: scale(1);
    transition: all .3s ease;
    :hover {
        box-shadow: 0px 4px 9px rgba(197, 193, 207, 0.8);
    }
`

const StyledImage = styled.img`
    border-radius: 2rem 2rem 0 0;
    width: 100%;
    height: 24rem;
    object-fit: cover;
`

const StyledContent = styled.div`
    padding: 0 4rem;
    height: 32rem;
    background:  ${({ theme }) => theme.color.gray};
    border-radius: 0 0 2rem 2rem;
`

const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10rem;
    h2 {
        font-size: 3.8rem;
        font-family: 'Lobster';
        color: #4C5458;
        font-weight: 500;
    }
`
const StyledText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 12rem;
`

const StyledP = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: 1.8rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`;

const StyledButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10rem;
`

const StyledLink = styled(Link)`
    background: ${({ theme }) => theme.color.blue};
    font-size: 2rem;
    padding: 0.4rem 7rem 0.8rem 7rem;
    font-variant: small-caps;
    color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
    transition: background .3s ease-out;
    :hover {
        background: #1616ff;
    }
`

export default News

export const query = graphql`
query MyQuery {
    allDatoCmsArticle {
        edges {
            node {
                title
                slug
                content
                image {url alt}
                gallery { alt url}
            }
        }
    }
}`