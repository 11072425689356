export const fadeAnimation = {
    hidden: { opacity: 0.9 },
    show: { opacity: 1, transition: { duration: 2, ease: "easeIn", staggerChildren: 0.2 } }
}

export const logoAnimation = {
    hidden: { opacity: 0.9 },
    show: { opacity: 1, transition: { duration: 2, ease: "easeIn", staggerChildren: 0.125 } }
}

export const fadeItem = {
    hidden: { y: -10, opacity: 0 },
    show: {
        y: 0,
        opacity: 1
    }
}

export const fadeFront = {
    hidden: { y: -10, opacity: 0 },
    show: {
        y: 0,
        opacity: 1
    }
}